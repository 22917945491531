
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContainerDefault from "../components/Layout/ContainerDefault";
import SiteMap from '../components/Shared/Banner/SiteMap';
import { Link } from "react-router-dom";

function Sitemap() {




return (
    <div>
        <ContainerDefault>
          <SiteMap/>
          <div className="container">
           <div className='row'>
            <div className='col-md-12 mt-5'>      <ul className="tree">
    <li><Link to='/'><span className='head'>Oasis Pumps Industry</span></Link>
        <ul>
            <li><Link to='/about'><span className='head'>About Oasis</span></Link>
                <ul>
                    <li><Link to='/about/#company-profile' relative="path"><span>Company Profile</span></Link>
                        <ul>
                            <li><Link to='/about/#quality-assurance' relative="path"><span>Quality Asurance</span></Link></li> 
                        </ul>
                        <ul>
                        <li><Link to='/about/#trinity-holdings' relative="path"><span>Trinity Holdings Subsidiaries</span></Link></li>
                        </ul>
                        <ul><li><Link to='/contact' relative="path"><span>Contact Us</span></Link></li></ul>
                    </li>
                   
                </ul>
            </li>
            <li><Link to='/products'><span className='head'>Products</span></Link>
                        <ul>
                            <li><Link to='/product-detail/close-impeller'><span>Close Impeller & Under Carriage</span></Link><ul>
                              <li><Link to='/product-detail/close-impeller'><span>Close Impeller OP</span></Link></li></ul>
                              <ul>
                              <li><Link to='/product-detail/under-carriage-pump'><span>Under Carriage Pump</span></Link></li></ul></li>
                            
                            <ul><li><Link to='/product-detail/open-impeller-op-s'><span>Open Impeller OP-S</span></Link></li></ul>
                            <ul><li><Link to='/product-detail/reciprocating-piston-type'><span>Reciprocating – Piston Type</span></Link></li></ul>
                            <ul><li><Link to='/product-detail/multi-purpose-self-priming-pump'><span>Multi Purpose Self Priming Pump</span></Link></li></ul>
                            <ul><li><Link to='/product-detail/irrigation-pump'><span>Irrigation Pump</span></Link></li></ul>
                            <ul><li><Link to='/product-detail/jetting-pump'><span>Jetting Pump</span></Link></li></ul>
                            <ul><li><Link to='/product-detail/booster-pump'><span>Booster Pump</span></Link></li></ul>
                            <ul><li><Link to='/product-detail/high-pressure-pump'><span>High Pressure Pump</span></Link></li></ul>
                            <ul><li><Link to='/product-detail/submersible-pump'><span>Submersible Pump</span></Link></li></ul>
                            <li><Link to='/product-detail/high-volume-op'><span>High Volume & High Head</span></Link>
                            <ul>
                              <li><Link to='/product-detail/high-volume-op'><span>High Volume OP</span></Link></li></ul>
                              <ul>
                              <li><Link to='/product-detail/high-head-op'><span>High Head OP</span></Link></li></ul></li>
                        </ul>
                    </li>
            <li><Link to='/solutions'><span className='head'>Solutions</span></Link>
            <ul>
                    <li><Link to='/solutions/#custom-manufacturing' relative="path"><span>Repair & Custom Manufacturing</span></Link>
                        <ul>
                            <li><Link to='/solutions/#hire' relative="path"><span>Hire</span></Link></li> 
                        </ul>
                        <ul>
                        <li><Link to='/solutions/#turnkey-projects' relative="path"><span>Turnkey Projects</span></Link></li>
                        </ul>
                        <ul><li><Link to='/solutions/#consulting-design' relative="path"><span>Consulting, Design & Method</span></Link></li></ul>
                        <ul><li><Link to='/solutions/#dewatering-techniques' relative="path"><span>Dewatering Techniques</span></Link></li></ul>
                    </li>
                   
                </ul>
            </li>
            <li><Link to='/solutions'><span className='head'>Development & Support</span></Link>
                <ul>
                    <li><Link to='/solutions/#production' relative="path"><span>Production & Testing</span></Link>
                        <ul>
                            <li><Link to='/solutions/#development' relative="path"><span>Market Development</span></Link></li>
                        </ul>
                        <ul>
                            <li><Link to='/solutions/#research' relative="path"><span>Research & Development</span></Link></li>
                        </ul>
                        
                    </li>
                </ul>
            </li>
            <li><Link to='/gallery'><span className='head'>Gallery</span></Link></li>
        </ul>
    </li>
</ul>
</div></div> </div>
        </ContainerDefault>
    </div>
  )
}

export default Sitemap