import React from 'react';
import HtmlParser from "react-html-parser";

function TrinityHoldingsSubsidiaries({Trinity}) {
  return (
    <div>
        <div className="TrinityHoldingsSubsidiaries" id="trinity-holdings">
            <div className="container">
                <div className="row">
                    
                    <div className="col-md-5">
                        <div className="TrinityHoldingsSubsidiaries-con">
                            <p className="txt2"> {HtmlParser(Trinity?.Trinity?.Title)}</p>
                            <p className="txt3">
                            {HtmlParser(Trinity?.Trinity?.Content)}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-7 TrinityHoldingsSubsidiaries-img-con ">
                        <img className="img-responsive  p-0 TrinityHoldingsSubsidiaries-img " src={process.env.REACT_APP_IMAGE_BASE_URL +  Trinity?.Trinity?.Image?.data?.attributes?.url} />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TrinityHoldingsSubsidiaries