import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Home from './page/home';
import AboutUs from '../src/page/about';
import Solutions from '../src/page/Solutions';
import Products from '../src/page/Products';
// import ProductsDetailed from '../src/page/ProductsDetailed';
import Gallery from './page/Gallery';
import ProductsDetailed from './page/product-detail/[slug]';
import News from './page/News';
import Career from './page/Career';
import ContactUs from './page/ContactUs';
import FeedbackForm from './page/FeedbackForm';
import EnquiryForm from './page/EnquiryForm';
import OurClients from './page/OurClients';
import Sitemap from './page/site-map';
import Linkedin from './page/linkedin';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import TagManager from 'react-gtm-module';

function App() {

  const [products, setProduct] = useState([]);
  const tagManagerArgs={
       gtmId: 'GTM-K7BPF3T4',
   };

  TagManager.initialize(tagManagerArgs);

  useEffect(() => {

    (async () => {
      const homes = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/products?populate[0]=Image"
        )
        .then((data) => {
          setProduct(data.data);
        });
        
    })();
  }, []);

  console.log(products, "ps");

  return (
    <div className="App">
      {/* <Home /> */}

<Router>
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/about" component={AboutUs} />
    <Route path="/solutions" component={Solutions} />
    <Route path="/products" component={Products} />
    <Route path="/productsdetailed" component={ProductsDetailed} />
    <Route path="/gallery" component={Gallery}/>
    {/* <Route path="/news" component={News}/> */}
    <Route path="/career" component={Career}/>
    <Route path="/contact" component={ContactUs}/>
    <Route path="/feedbackform" component={FeedbackForm}/>
    <Route path="/enquiry" component={EnquiryForm}/>
    <Route path="/ourclients" component={OurClients}/>
    <Route path="/product-detail/" component={ProductsDetailed}/>
    <Route path="/site-map" component={Sitemap}/>
    <Route path="/news" component={Linkedin}/>

  </Switch>
</Router>
    </div>
  );
}

export default App;
