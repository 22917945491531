
import React, { useEffect, useRef, useState } from "react";
import "react-client-captcha/dist/index.css";
import { api } from "../../../lib/api";
import axios from "axios";
import validator from "validator";
import HtmlParser from "react-html-parser";
import { useForm } from "react-hook-form";

import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

function Contact() {
    const captchaRef1 = useRef(null);

  useEffect(() => {
    loadCaptchaEnginge(4,'#EFF7FF','black','upper');
  });
  const [address, setAddress] = useState([]);


  useEffect(() => {

    (async () => {
      const homes = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/footer?populate[0]=*"
        )
        .then((data) => {
          setAddress(data.data.data.attributes);
        });
        
    })();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // name: "",
      // project: "",
    },
  });
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  const onSubmit = (userData) => {
    const data = {
      data: userData,
    };
    data.data.title = "Careers";

    let nameFormat = /^[^-\s][a-zA-Z_\s-.]+$/g;

    if (!data.data.Name.match(nameFormat)) {
      setError("Enter a Valid Name");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

 

 //   let numberFormat = /^[0-9]\d{9,11}$/;
    // let isMobile = validator.isMobilePhone(data.data.mobile);
   // if (!data.data.Phone.match(numberFormat)) {
    //  setError("Enter a Valid Mobile Number");
     // setTimeout(function () {
       // setError(false);
     // }, 5000);
     // return;
   // }

    if (!validator.isEmail(data.data.Email)) {
      setError("Enter a Valid Email");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

    api
      .post("/contact-forms", data)
      .then(function (response) {
        if (response) {
          document.getElementById("contact-form").reset();
          setMessage("Thank you for Contacting Us");
          window.location.reload();
          setTimeout(function () {
            setMessage(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        document.getElementById("contact-form").reset();
        setMessage("Thank you for Contacting Us");
       // console.log(error);
      });
  };
  return (
    <div>
        <div className='contact'>
            <div className='container'>
                <div className='row contactus'>
                    <div className='col-md-12 col-lg-6 GetinTouch'>
                        <div className='GetinTouch-data'>
                            <p className='GetinTouch-data-Title'>Get in Touch</p>
                            <p className='GetinTouch-data-txt'>{HtmlParser(address?.Content1)}</p>
                        </div>
                        <div className='GetinTouch-data-info d-flex'>
                            <img className='GetinTouch-icons' src='/asset/icons/phone-call-2.png'/>
                            <img className='GetinTouch-icons-new' src='/asset/icons/phone-call.png'/>
                            <div className='GetinTouch-icons-con'>
                                <p className='icon-type mb-0' >Phone Number</p>
                                <p className='icon-data'>{address?.PhoneNumber}</p>
                            </div> 
                        </div>

                        <div className='GetinTouch-data-info d-flex'>
                            <img className='GetinTouch-icons' src='/asset/icons/email-2.png'/>
                            <img className='GetinTouch-icons-new' src='/asset/icons/email.png'/>
                            <div className='GetinTouch-icons-con'>
                                <p className='icon-type mb-0' >Email</p>
                                <p className='icon-data'>{address?.Email}</p>
                            </div> 
                        </div>

                        <div className='GetinTouch-data-info d-flex'>
                            <img className='GetinTouch-icons' src='/asset/icons/location-2.png'/>
                            <img className='GetinTouch-icons-new' src='/asset/icons/locs.png'/>
                            <div className='GetinTouch-icons-con'>
                                <p className='icon-type mb-0' >Address</p>
                                <p className='icon-data'>{HtmlParser(address?.Address)}</p>
                            </div> 
                        </div>

                    </div>
                    <div className='col-md-12 col-lg-6 Connectwithus'>
                        <div className='Connectwithus-data'>
                            <p className='Connectwithus-data-Title'>Connect with us</p>
                            <form onSubmit={handleSubmit(onSubmit)}  id="contact-form">

                                <div className='form-group '>
                                    <label>Name</label>
                                    <input
                  label={"Full Name"}
                  className={"form-control"}
                  {...register("Name", { required: true })}
                />
                {errors.Name && (
                  <span className="errors">This field is required</span>
                )}                                </div>
                                <div className='form-group '>
                                    <label>Email</label>
                                    <input
                  label={"Email"}
                  className={"form-control"}
                  {...register("Email", { required: true })}
                />
                {errors.Email && (
                  <span className="errors">This field is required</span>
                )}                                </div>
                                <div className='form-group '>
                                    <label>Phone</label>
                                    <input
                  label={"Mobile"}
                  className={"form-control"}
                  {...register("Phone", { required: true })}
                />
                {errors.Phone && (
                  <span className="errors">This field is required</span>
                )}                                </div>
                                <div className='form-group '>
                                    <label>Subject</label>
                                    <input
                  label={"Mobile"}
                  className={"form-control"}
                  {...register("Subject", { required: true })}
                />
                {errors.Subject && (
                  <span className="errors">This field is required</span>
                )}                                       </div>
                                <div className='d-flex form-group captcha     align-items-end'>
                                    <input className='form-control ' ref={captchaRef1} placeholder='Verify Captcha' type="text" /> 
                                    <LoadCanvasTemplateNoReload />
                                </div>
                                
        
                                {message ? <div className="success-msg">{message}</div> : null}
              {error ? <div className="error-msg">{error}</div> : null}
                                <div className="contactsub-div" >
                                    {/* <button className="btn4 contactsub" onClick={handleCaptcha1} >Send Message</button> */}
                                    <button  type="submit" className="btn btn-color btn-l-r mt-4 pt-2"  >Send Message</button>
                                </div>
                                
     
                               
                                

                            </form>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
        <div className="map">
        {HtmlParser(address?.Map)}
        </div>
        
    </div>
    
  )
}

export default Contact
