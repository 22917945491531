import React from 'react'
import Pagination from './Pagination';

function SubNews({Mnews,
    postsPerPage,
    totalPost,
    paginate,
    currentPage,
    previousPage,
    nextPage,
    setCurrentPage,}) {
  return (
    <div>
        <div className='SubNews'>
            <div className='container'>
                <div className='row'>
                    {Mnews?.map((value, index)=>(
                        <div className='col-md-4 p-0 SubNews-col'>
                           
                        <img className='SubNews-img' src={process.env.REACT_APP_IMAGE_BASE_URL + value?.attributes?.Thumbnail?.data?.attributes?.url}/>
                        <div className='SubNews-con'>
                            <div className='SubNews-con-det'>
                                <p className='txt19 SubNews-con-title'>{value?.attributes?.Title}</p>
                                <p className='txt22 SubNews-con-desc'>
                                {value?.attributes?.ShortDescription}
                                </p>
                                <p className='SubNewsDate'>{value?.attributes?.Date}</p>
                            </div>

                        </div>
                    </div>
                    ))}
                    

                    <Pagination
                        postPerPage={postsPerPage}
                        totalPosts={totalPost}
                        paginate={paginate}
                        currentPage={currentPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        />
                </div>
            </div>
        </div>
    </div>
  )
}

export default SubNews