import axios from "axios";



export function getStrapiURL(path = "") {
    return `${process.env.REACT_APP_API_BASE_URL}${path}`;
}

// Helper to make GET requests to Strapi
export async function fetchAPI(path) {
    const requestUrl = getStrapiURL(path);
    const response = await fetch(requestUrl);
    const data = await response.json();
    return data;
}


export  const api = axios.create({
  baseURL: process.env.REACT_APP_NEXT_PUBLIC_API_BASE_URL,
});

// Set config defaults when creating the instance



