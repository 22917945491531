import React from 'react';
import ProductSlider from './ProductSlider';
import HtmlParser from "react-html-parser";

function ProductSliderContent({Slidercnt}) {
  return (
    <div>
      <div className='ProductSliderContent'>
        <div className="container">
          <div className="row ProductSliderContent-row">
            <div className="col-md-6 p-0 ProductSliderContent-col1">
              <p className="Productpath p-0">Oasis Pumps Industry > Products >
              <span className='txt4'>{Slidercnt?.attributes?.Title}</span> </p>
              <div className="">
                <ProductSlider Prslider={Slidercnt?.attributes?.Gallery?.data}/>
              </div>

            </div>
            <div className="col-md-6 p-0 ProductSliderContent-col2">
              <div className='ProductSliderContent-det'>
              <p className="ProductSliderContent-title txt2">{Slidercnt?.attributes?.Title}</p>
              {/* <p className="ProductSliderContent-subtitle txt21">Details</p> */}
              <p className=''>
              {HtmlParser(Slidercnt?.attributes?.Description)}
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSliderContent