// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

/* Main CSS */
.grid-wrapper > div {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: 270px;
  grid-auto-flow: dense;
}

.grid-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-wrapper > div > img {
  width: 100%;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
}

.grid-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: 270px;
  grid-auto-flow: dense;
}

.grid-wrapper .wide {
  grid-column: span 1;
}

.grid-wrapper .tall {
  grid-row: span 2;
}

.grid-wrapper .big {
  grid-column: span 2;
  grid-row: span 2;
}

@media (max-width: 767px) {
  .grid-wrapper {
    display: block;
  }
  .grid-wrapper div {
    margin-top: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Gallery.scss"],"names":[],"mappings":"AAAA;EACC,SAAA;EACA,UAAA;EACA,sBAAA;AACD;;AAMA;EACC,eAAA;EACA,YAAA;EACA,sBAAA;EACA,qBAAA;AAHD;;AAMA,aAAA;AACA;EACC,aAAA;EACA,cAAA;EACA,2DAAA;EACA,qBAAA;EACA,qBAAA;AAHD;;AAKA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;AAFD;;AAIA;EACC,WAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;AADD;;AAKA;EACC,aAAA;EACA,cAAA;EACA,2DAAA;EACA,qBAAA;EACA,qBAAA;AAFD;;AAIA;EACC,mBAAA;AADD;;AAGA;EACC,gBAAA;AAAD;;AAEA;EACC,mBAAA;EACA,gBAAA;AACD;;AACA;EACI;IACI,cAAA;EAEN;EACE;IACI,gBAAA;EACN;AACF","sourcesContent":["* {\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tbox-sizing: border-box;\r\n}\r\n// html,\r\n// body {\r\n// \tbackground: linear-gradient(45deg, #190f2c, #200b30);\r\n// \tpadding: 15px;\r\n// }\r\nimg {\r\n\tmax-width: 100%;\r\n\theight: auto;\r\n\tvertical-align: middle;\r\n\tdisplay: inline-block;\r\n}\r\n\r\n/* Main CSS */\r\n.grid-wrapper > div {\r\n\tdisplay: grid;\r\n\tgrid-gap: 10px;\r\n\tgrid-template-columns: repeat(auto-fit, minmax(350px, 1fr));\r\n\tgrid-auto-rows: 270px;\r\n\tgrid-auto-flow: dense;\r\n}\r\n.grid-wrapper > div {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n}\r\n.grid-wrapper >  div > img {\r\n\twidth: 100%;\r\n\tcursor: pointer;\r\n\theight: 100%;\r\n\tobject-fit: cover;\r\n\t// border-radius: 5px;\r\n}\r\n\r\n.grid-wrapper {\r\n\tdisplay: grid;\r\n\tgrid-gap: 10px;\r\n\tgrid-template-columns: repeat(auto-fit, minmax(350px, 1fr));\r\n\tgrid-auto-rows: 270px;\r\n\tgrid-auto-flow: dense;\r\n}\r\n.grid-wrapper .wide {\r\n\tgrid-column: span 1;\r\n}\r\n.grid-wrapper .tall {\r\n\tgrid-row: span 2;\r\n}\r\n.grid-wrapper .big {\r\n\tgrid-column: span 2;\r\n\tgrid-row: span 2;\r\n}\r\n@media(max-width:767px){\r\n    .grid-wrapper {\r\n        display: block;\r\n               \r\n    }\r\n    .grid-wrapper div {\r\n        margin-top: 20px;\r\n               \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
