import React, { useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import Progress from "../Progress/Progress";

import "../../styles/Fileupload.scss";

export default function Fileupload() {
  const inputRef = useRef();
  const [filenames, setNames] = useState([]);

  const fileHandler = (files) => {
    const extension = files[0].name.split(".")[1]?.toLowerCase();

    if (extension !== undefined) {
      const fNames = Object.keys(files).map((name) => {
        return {
          name: files[name].name,
          icon: files[name].name.split(".")[1]?.toUpperCase().trim()
        };
      });
      setNames((prev) => [...prev, fNames].flat());
    } else {
      alert("file type not supported");
    }
  };

  const filePicker = () => {
    inputRef.current.click();
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center align-items-center">
          <img className='upload-img' src="/asset/icons/resume.svg" />
          <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
            <p className="placeholder DD">
              DRAG FILE HERE <br /> OR <span className="brow">BROWSE</span>
            </p>
            <input
              accept=".doc, .docx,  .pdf, .jpg, .jpeg,.png"
              value=""
              style={{ visibility: "hidden", opacity: 0 }}
              ref={inputRef}
              // multiple="multiple"
              type="file"
              onChange={(e) => fileHandler(e.target.files)}
            />
          </FileDrop>
      </div>
      

      <div className="progressContainer">
        {filenames &&
          filenames.map((file, i) => (
            <Progress key={i} name={file.name} icon={file.icon} />
          ))}
      </div>
    </div>
  );
}
