import React, { useEffect, useRef, useState } from "react";
import Fileupload from '../../common/Fileupload';
import { api } from "../../../lib/api";
import validator from "validator";
import { useForm } from "react-hook-form";
import HtmlParser from "react-html-parser";



function Applyform({apply}) {


    
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // name: "",
      // project: "",
    },
  });
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  const onSubmit = (userData) => {
    const data = {
      data: userData,
    };
    data.data.title = "Careers";

    let nameFormat = /^[^-\s][a-zA-Z_\s-.]+$/g;

    if (!data.data.Name.match(nameFormat)) {
      setError("Enter a Valid Name");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

 

    let numberFormat = /^[0-9]\d{9,11}$/;
    // let isMobile = validator.isMobilePhone(data.data.mobile);
    if (!data.data.PhoneNumber.match(numberFormat)) {
      setError("Enter a Valid Mobile Number");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

    if (!validator.isEmail(data.data.Email)) {
      setError("Enter a Valid Email");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

    api
      .post("/career-forms", data)
      .then(function (response) {
        if (response) {
          document.getElementById("contact-form").reset();
          setMessage("Thank you for Contacting Us");
          window.location.reload();
          setTimeout(function () {
            setMessage(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
        <div className=''>
            <div className='container'>
                <div className='row'>
                    <div className='Applyform' id='aPP'>
                        <div className='Applyform-bg'>
                            <div className='Applyform-cont'>
                                <p className='txt2'>{HtmlParser(apply)}</p>
                                <form onSubmit={handleSubmit(onSubmit)}  id="contact-form">
                                    <div className='row'>
                                        <div className='form-group col-md-6'>
                                        <label>Name</label>
                                        <input
                  label={"Full Name"}
                  className={"form-control"}
                  {...register("Name", { required: true })}
                />
                {errors.Name && (
                  <span className="errors">This field is required</span>
                )}                                                </div>

                                        <div className='form-group col-md-6'>
                                        <label>Subject</label>
                                        <input
                  label={"Mobile"}
                  className={"form-control"}
                  {...register("Subject", { required: true })}
                />
                {errors.Subject && (
                  <span className="errors">This field is required</span>
                )}                                                                             </div>

                                        <div className='form-group col-md-6'>
                                        <label>Your Email</label>
                                        <input
                  label={"Email"}
                  className={"form-control"}
                  {...register("Email", { required: true })}
                />
                {errors.Email && (
                  <span className="errors">This field is required</span>
                )}                                                                 <div className='form-group'>
                                        <label>Phone</label>
                                        <input
                  label={"PhoneNumber"}
                  className={"form-control"}
                  {...register("PhoneNumber", { required: true })}
                />
                {errors.Phone && (
                  <span className="errors">This field is required</span>
                )}                                                            </div>
                                        
                                        </div>
                                        <div className='form-group col-md-6'>
                                        <label>
                                        file
                                        </label>
                                        {/* <Upload/> */}
                                        <div className='form-control file-upload'>
                                            <div class="image-upload ">
                                                <label for="file-input">
                                                    
                                                    <Fileupload/>
                                                   
                                                    
                                                </label>

                                               
                                            </div>
                                        </div>
                                        

                                        </div>

                                      <div className='col-md-12'>
                                      <p className='trashbin' ><span className='delete'>Delete</span></p>
                                      </div>
                                      {message ? <div className="success-msg">{message}</div> : null}
              {error ? <div className="error-msg">{error}</div> : null}
                                        <div className='col-md-12 button-apply '>
                                            
                                        <button type="submit" className='btn4'>Apply</button>
                                        </div>

                                    </div>                            
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Applyform;
