import React from 'react'
import ContainerDefault from "../components/Layout/ContainerDefault";
import Feedback from '../components/Shared/FeedbackForm/Feedback';

function FeedbackForm() {
  return (
    <div>
        <ContainerDefault>

         <Feedback/>

        </ContainerDefault>
    </div>
  )
}


export default FeedbackForm