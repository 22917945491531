import React from 'react'
import ContainerDefault from "../components/Layout/ContainerDefault";
import Enquiry from '../components/Shared/EnquiryForm/Enquiry';
function EnquiryForm() {
  return (
    <div>
            <ContainerDefault>

                <Enquiry/>

            </ContainerDefault>
    </div>
  )
}

export default EnquiryForm