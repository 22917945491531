import React from 'react';
import HtmlParser from 'react-html-parser';

function CustomManufacturing({Solutions}) {
  return (
    <div>
        
        <div className="CustomManufacturing" id="custom-manufacturing">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 p-0">
                        <img className='CustomManufacturing-image' src={process.env.REACT_APP_IMAGE_BASE_URL + Solutions?.Solution1?.Image?.data?.attributes?.url} />
                    </div>
                    <div className="col-md-5 CustomManufacturing-txt d-flex">
                        <div>
                        <p className='CustomManufacturing-title txt2'> {HtmlParser(Solutions?.Solution1?.Title)}</p>
                        <p className='CustomManufacturing-sub txt3'>
                        {Solutions?.Solution1?.Content}
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CustomManufacturing