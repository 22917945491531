import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContainerDefault from "../components/Layout/ContainerDefault";
import DefaultBanner from '../components/Shared/Banner/DefaultBanner';
import GalleryImages from '../components/Shared/Gallery/GalleryImages';
function Gallery() {

  const gallery = {gal: "gallery"}
  const [banner, setBanner] = useState([]);

  const [gall, setGallery] = useState([]);


  useEffect(() => {

    (async () => {
      const homes = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/galleries?populate[0]=Image"
        )
        .then((data) => {
          setGallery(data.data.data);
        });
        
    })();
  }, []);

  useEffect(() => {

    (async () => {
      const bannerss = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/gallery-page?populate[0]=BannerImage"
        )
        .then((data) => {
          setBanner(data.data.data.attributes);
        });
        
    })();
  }, []);

  return (
    <div>
        <ContainerDefault>

            <DefaultBanner  content={banner?.BannerContent} banimg={process.env.REACT_APP_IMAGE_BASE_URL + banner?.BannerImage?.data?.attributes?.url}/>
            <GalleryImages gallery={gall}/>

        </ContainerDefault>
    </div>
  )
}

export default Gallery