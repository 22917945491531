import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Script from "next/script";
import ContainerDefault from "../components/Layout/ContainerDefault";
import DefaultBanner from '../components/Shared/Banner/DefaultBanner';
import { LinkedInEmbed } from 'react-social-media-embed';


function Linkedin() {
    const [lnews, setNews] = useState([]);
    const [banner, setBanner] = useState([]);
  
    useEffect(() => {
  
      (async () => {
        const aboutss = await axios
          .get(
            process.env.REACT_APP_API_BASE_URL + "/news?populate[0]=Thumbnail&populate[1]=Image"
          )
          .then((data) => {
            setNews(data.data.data);
          });
          
      })();
    }, []);
  
    useEffect(() => {
  
      (async () => {
        const bannerss = await axios
          .get(
            process.env.REACT_APP_API_BASE_URL + "/news-page?populate[0]=BannerImage"
          )
          .then((data) => {
            setBanner(data.data.data.attributes);
          });
          
      })();
    }, []);
  return (
    <div>
        <ContainerDefault>
            <DefaultBanner  content="Latest Feed" banimg={process.env.REACT_APP_IMAGE_BASE_URL + banner?.BannerImage?.data?.attributes?.url}/>      
            <div>
      <div className="latest_news_area">
        <div className="latest_news latest_feed">
          <div className="container">
            <div className="row">
              <div className="banner-txt">
                <p className="banner-num">06</p>
                <span className="banner-title">Latest Feed</span>
              </div>
            </div>
            <div className="row d-block">
             
             
              <iframe id="wallsio-iframe" src="https://my.walls.io/h4bdg?nobackground=1&amp;show_header=0&amp;scrollable=0" width="100%" height="2800px" title="My social wall"></iframe>
            </div>

          </div>
        </div>
      </div>
    </div>
        </ContainerDefault>
    </div>
  )
}

export default Linkedin