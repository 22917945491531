import React from 'react'

function OurTeam({Team}) {
  return (
    <div>
        <div className="OurTeam-about" id="our-team">
            <div className="container">
        <p className="txt16">Our Team</p>
        <div className="row">
            {Team?.Team?.map((datas) => (
            <div className="col-sm-6 col-md-6 col-lg-3 OurTeam-about-img" key={datas.id}>
                <img className="img-responsive" src={process.env.REACT_APP_IMAGE_BASE_URL + datas?.Image?.data?.attributes?.url}/>
                <div className="OurTeam-about-con">
                    <div className="OurTeam-about-con-det">
                        <p className="OurTeam-about-name txt10 m-0">{datas?.Title}</p>
                        <p className="OurTeam-about-position txt17 m-0">{datas?.Subtitle}</p>
                    </div>
                    
                </div>
            </div>
            ))}
        </div>
        </div>

        </div>
        
    </div>
  )
}

export default OurTeam