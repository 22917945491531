import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import React  from "react";
import { useEffect } from "react";

export default function Pagination({
  postPerPage,
  totalPosts,
  paginate,
  currentPage,
  previousPage,
  nextPage,
}) {
  const pageNumber = [];
  for (let i = 1; i <= Math.ceil(totalPosts?.length / postPerPage); i++) {
    pageNumber.push(i);
  }

   useEffect(() => {
     window.scrollTo({ behavior: "smooth", bottom: "100px" });
   }, [currentPage]);


  return (
    <div className="pagination">
      <a onClick={() => previousPage()} className={currentPage === 1 ? "blocks arr disable" : "blocks arr"}>
        <AiOutlineArrowLeft size={"20"} />
      </a>
        <div className="pagenumber">
      {pageNumber.map((number) => (
        <a
          key={number}
          onClick={() => paginate(number)}
          className={currentPage == number ? "blocks active" : "blocks "}
        >
          {number}
        </a>
      ))}
      {/* <a className="blocks">3</a>
      <a className="blocks">4</a>
      <a className="blocks">5</a>
      <a className="blocks">..</a>
      <a className="blocks">12</a>
      <a className="blocks">13</a> */}
      </div>

      <a onClick={() => nextPage()} className={currentPage === pageNumber?.length ? "blocks arr disable" : "blocks arr"} >
        <AiOutlineArrowRight size={"20"} />
      </a>
      
      
    </div>
  );
}
