import React, {useState} from 'react';
import {BsArrowRight} from 'react-icons/bs';
function ProductsList({Productlist, index=0, Mnews, lim= 3}) {
    const [Limit, setLimit] = useState(8);
    const test = Productlist?.data?.length;
  return (
    <div>
        <div className="ProductsList">
            <div className="container">
                <div className="row ProductsList-row ">
                    <div className="col-md-8 ProductsList-col1  ">
                        <div className="row">
                     {Productlist?.data?.slice(index, Limit)?.map((datas) =>(
                        <div className="col-md-6 p-0 mt-4 ProductsList-image-col" key={datas.id}>
                            <a href={"/product-detail/" + datas?.attributes?.Slug}>
                            <div className="ProductsList-image-div">
                            <img className="ProductsList-image" src={process.env.REACT_APP_IMAGE_BASE_URL + datas?.attributes?.Image?.data?.attributes?.url} alt="ProductsList-image" />
                            </div>

                            <div className="col-md-6 ProductsList-image-desc">
                            <p className="" >{datas?.attributes?.Title}</p>
                            
                        </div>
                        </a>
                        </div>
                        ))}
                        <div className='Button3 d-flex justify-content-around' >
                            {Limit < test ?(
                        <a  onClick={() => setLimit(Limit + 2)}  className="btn btn-color btn-l-r">Load More</a>) : ("")}
                        </div>


                        
                        </div>
                    </div>
                    
                        <div className="col-md-4 ProductsList-col2 ">
                            <div className='row'>
                                <div className='RelatedProducts-div '>
                                    <div className=''>
                                        <p className='RelatedProducts-title txt19'>Other Related Products</p>
                                    </div>
                                    <div className='RelatedProducts-sub-div'>
                                        
                                    {Productlist?.data?.map((datas) =>(
                                    <p  key={datas.id} className='RelatedProducts-sub txt-8'><a href={"/product-detail/" + datas?.attributes?.Slug}>{datas?.attributes?.Title}</a> </p>
                                    ))}
                                    
                                        
                                    </div>
                                </div>

                                {/* <div className='LatestNews-div '>
                                    <div className=''>
                                        <p className='LatestNews-title txt19'>Latest News</p>
                                    </div>
                                    <div className='LatestNews-sub-div'>
                                        

                                    {Mnews?.slice(index, lim).map((datas) =>  (
                                    <p  key={datas.key} className='LatestNews-sub txt8'>{datas?.attributes?.Title}
                                    <br></br>
                                    <span className='txt20'>{datas?.attributes?.Date}</span>
                                    
                                    </p>
                                    ))}
                                    
                                   
                                        
                                    </div>
                                    <div className='ReadALLNews'>
                                    <a href='news' className="btn2 btn2-color btn2-l-r ">
                                    Read All News&nbsp;<BsArrowRight/>
                                    </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
  )
}

export default ProductsList