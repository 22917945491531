import React from 'react';
import { api } from "../../../lib/api";
import axios from 'axios';
import { useForm } from "react-hook-form";



function Novacancy() {
  const [image, setImage] = React.useState('');

  function onSubmit(){
    const formData = new FormData()
    formData.append('file', image)
    axios.post('http://139.59.6.114:2340/api/upload', formData).then((res) => {
      console.log(res, "res");
    }).catch(error =>{
      console.log(error.message, "error");
    });
  }

   
  return (
    <div>
        <div className='Novacancy'>
            <div className='container'>
                <div className=''>
                    <div className='row Novacancy-div p-0'>
                        <img className='Novacancy-img p-0' src='/asset/images/NoVac3.png'/>
                        <p className='Novacancy-text m-0'>Sorry, no vacancy available at the moment</p>
                    </div>
                    <div className='ttlvac text-center'>
                        <p className='mb-0 Novacancy-cv-det'>Please send us your resume <a href="mailto:opi@trinityholdings.com">opi@trinityholdings.com</a>

</p>
                        {/* <form  onSubmit={onSubmit}  id="contact-form">
                          <div className="d-flex align-items-center justify-content-center flex-wrap Novacancy-cv">
                        <input className='pt-2' name="file" type='file' />
                       
                       <button type="submit" className='btn btn-color  btn-l-r'>Send</button>
                       </div>
                        </form> */}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Novacancy