import React from 'react'

function SolutionsBanner({content, banimg}) {
  return (
    <div>
        <div className="Solutions-banner">
            <img className='Solutions-banner-image' src={banimg} alt='banner' />
            <div className="container">
                <div className='row Solutions-banner-text'>
                    <p className='Solutions-banner-text-title p-0'>Solutions</p>
                    <p className='Solutions-banner-text-sub col-md-7 p-0'>
                  {content}
                    </p>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default SolutionsBanner