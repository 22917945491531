import React, {useState, useEffect} from 'react';
function SiteMap({content, banimg}) {


  return (
    <div>
      <div className="Def-banner">
        <img className='Def-banner-image' src='asset/images/Bclients.png' alt='banner' />
      <div className='container'>
        <p className='Def-banner-text '>Site Map</p>
      </div>
      </div>
    </div>
  );
}

export default SiteMap