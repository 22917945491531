import React, {useState, useEffect} from 'react';
import axios  from 'axios';
// import {useRouter} from "next/router";
function DefaultBanner({content, banimg}) {
  // const routers = useRouter();
  const [banner, setBanner] = useState([]);



  useEffect(() => {

    (async () => {
      const homes = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/banners?populate[0]=Image"
        )
        .then((data) => {
          setBanner(data.data);
        });
        
    })();
  }, []);


  return (
    <div>
      <div className="Def-banner">
        <img className='Def-banner-image' src={banimg} alt='banner' />
      <div className='container'>
        <p className='Def-banner-text '>{content}</p>
      </div>
      </div>
    </div>
  );
}


export default DefaultBanner