import React, { useState, useEffect } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ModalImage from "react-modal-image";
import Modal from 'react-modal';


export default function ImageTab({Prslider}) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(null);


  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {

    const img = document.querySelector(".slick-slide.slick-active img").getAttribute("src") 
    setIsOpen(!modalIsOpen);
    setValues(img)
  }
 

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settings = {
    dots: false,
    arrows: false,
     infinite: true,
    speed: 500,
    asNavFor: ".slider-nav",
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  const setting = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    focusOnSelect: true,
    slidesToScroll: 1,
    centerMode: true,
    asNavFor: ".slider-for",
    // vertical: true,
    autoplay: true,
    responsive: [{
      breakpoint: 1200,
      setting: {
        slidesToShow: 4,
        slidesToScroll: 1,
        
      }
    }]

  };


  return (
    <div className="text-center">
      <div className="row">
        <div className="col-lg-12">
          <Slider
            {...settings}
            asNavFor={nav2}
            ref={(slider) => setSlider1(slider)}
          >
            {Prslider?.map((product) => (
                
                
              <div  key={product.id}> <img  onClick={() => openModal(product?.attributes?.url)} className="productslide-img" src={process.env.REACT_APP_IMAGE_BASE_URL + product?.attributes?.url}/>
               </div>
            ))}
            
           
          </Slider>
            <Modal
            className="pslide"
              isOpen={modalIsOpen}
              shouldCloseOnOverlayClick={true}
              onRequestClose={closeModal}
            >

<button className="open-modal" onClick={closeModal}>X</button> 
<img   className="productslide-img" src={ values}/>
            
         
          </Modal>
        </div>
        </div>
        <div className="row childslider">
        <div className="col-lg-12">
          <Slider
            {...setting}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
            className=""
          >
            {Prslider?.map((product) => (
              <div className="pro-item" key={product.id}>
                <img className="productslide-img-scroll" src={process.env.REACT_APP_IMAGE_BASE_URL + product?.attributes?.url}/>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}