import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Applyform from "./Applyform";
import HtmlParser from "react-html-parser";


function CurrentOpportunities({Car}) {

  return (
    <div>
      <div className='CurrentOpportunities'>
        <div className='container'>
          
            <p className='txt2 CurrentOpportunities-main-title'>Current Opportunities</p>
            {/* <p className='CurrentOpportunities-sub-title'>Open Positions</p> */}


            <div className='CurrentOpportunities-list row-hide'>
              <div className='row CurrentOpportunities-list-row'>
                {Car?.map((datas) => (
                <div className='col-md-6 CurrentOpportunities-div1' key={datas?.id}>
                  <div className='CurrentOpportunities-list-div'>
                    <div className='CurrentOpportunities-list-div-con'>
                      <div className='d-flex justify-content-between CurrentOpportunities-list-div-content'>
                        <p className='txt23 m-0'>{HtmlParser(datas?.attributes?.Title)}</p>
                        <p className='txt20 font-weight-bold '>{datas?.attributes?.Date}</p>
                      </div>
                      
                      <p className='CurrentOpportunities-cont '>{datas?.attributes?.Location}</p>
                      <p className='CurrentOpportunities-det'>{datas?.attributes?.ShortDescription}
                      </p>
                      <div className='d-flex justify-content-between align-items-center CurrentOpportunities-list-div-content '>
                        <p className='p-0 m-0'><span className="CurrentOpportunities-exp ">Experience :</span> {datas?.attributes?.Experience}</p>
                        <Popup trigger={<a className='btn3'  >Apply Now</a>}>
                          <div>
                            <Applyform apply={datas?.attributes?.Title}/>
                          </div>
                        </Popup>
                        
                      </div>
                      
                    </div>

                  </div>
                </div>
                ))}

              </div>
          </div>



          {/* <div className={click? 'Applyform apply-show':'Applyform'} id='aPP'>
            <div className='Applyform-bg'>
              <div className='Applyform-cont'>
                <p className='txt2'>Business Executive</p>
                <form>
                  <div className='row'>
                    <div className='form-group col-md-6'>
                    <label>Name</label>
                    <input type="text" className='form-control ' placeholder='Enter your name'></input>
                    </div>

                    <div className='form-group col-md-6'>
                    <label>Cover Letter</label>
                    <input type="text" className='form-control' placeholder='Enter your Subject'></input>
                    </div>

                    <div className='form-group col-md-6'>
                    <label>Your Email</label>
                    <input type="text" className='form-control' placeholder='Enter your email'></input>
                    </div>
                    <div className='form-group col-md-6'>
                    <label>
                      file
                    </label>

                    </div>

                    <div className='form-group col-md-6'>
                    <label>Phone</label>
                    <input type="text" className='form-control' placeholder='Enter your number'></input>
                    </div>

                    <div className='col-md-12 button-apply '>
                      <button className='btn4'>Apply</button>
                    </div>

                  </div>

               
                </form>

              </div>

            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default CurrentOpportunities