import React, { useState, useRef, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { MdMenu } from "react-icons/md";
import { useDetectClickOutside } from 'react-detect-click-outside';
import Image from "next/image";
import Link from "next/link";
import "../../styles/main.scss";

function Header() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click)
  

  const [clicks, setClicks] = useState(false);
  const handleClicks = () => setClicks(!clicks)
  const ref = useRef();
  useOnClickOutside(ref, () => setClicks(false));

  const [product, setProduct] = useState([]);
  const location = useLocation();
  const loc = location.pathname;

  console.log(loc, "sd");

  useEffect(() => {
    (async () => {
      const homes = await axios
        .get(process.env.REACT_APP_API_BASE_URL + "/products?populate[0]=Image")
        .then((data) => {
          setProduct(data.data.data);
        });
    })();
  }, []);

  return (
    <>
      <div className="header">
        {/* <div className={
                click
                  ? "header sss"
                  : "header"
              }> */}
        <div className="position-relative ">
          <div className="row">
            <div className="col-md-12 p-0">
              <header>
                <nav className="navbar navbar-expand-lg navbar-light col-md-12 p-0 d-flex justify-content-between  text-center ">
                  <div className="container header-container">
                    <div className="Header-logo">
                      <a href="/">
                        <img
                          src="/asset/images/logo-3.png"
                          alt="logo"
                          width={150}
                          height={65}
                        />
                      </a>
                    </div>
                    <button
                      className="navbar-toggler"
                      onClick={handleClick}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsibleNavbar"
                    >
                      <span className="navbar-toggler-icon dark"></span>
                    </button>
                    <div
                      className={
                        click
                          ? "collapse navbar-collapse naa justify-content-end show"
                          : "collapse navbar-collapse naa justify-content-end"
                      }
                      id="collapsibleNavbar"
                    >
                      <ul className="navbar-nav align-items-center ">
                        {/* {click ? "nav-link navbar-nav active" : "nav-link navbar-nav"} */}
                        <li
                          className={
                            loc == "/" ? "nav-item active" : "nav-item"
                          }
                        >
                          {/* <Link href="/home"> */}
                          <a href="/" className="nav-link">
                            Home
                          </a>
                          {/* </Link> */}
                        </li>

                        <li
                          className={
                            loc == "/about" ? "nav-item active" : "nav-item"
                          }
                        >
                          {/* <Link href="/about"> */}
                          <a href="/about" className="nav-link">
                            About Us
                          </a>
                          {/* </Link> */}
                        </li>

                        <li
                          className={
                            loc == "/solutions" ? "nav-item active" : "nav-item"
                          }
                        >
                          {/* <Link href="/solutions"> */}
                          <a href="/solutions" className="nav-link">
                            Solutions
                          </a>
                          {/* </Link> */}
                        </li>

                        <li
                          className={
                            loc == "/gallery" ? "nav-item active" : "nav-item"
                          }
                        >
                          {/* <Link href="/gallery"> */}
                          <a href="/gallery" className="nav-link">
                            Gallery
                          </a>
                          {/* </Link> */}
                        </li>

                        <li
                          className={
                            loc == "/news" ? "nav-item active" : "nav-item"
                          }
                        >
                          {/* <Link href="/news"> */}
                          <a href="/news" className="nav-link">
                            News
                          </a>
                          {/* </Link> */}
                        </li>

                        <li
                          className={
                            loc == "/career" ? "nav-item active" : "nav-item"
                          }
                        >
                          {/* <Link href="/career"> */}
                          <a href="/career" className="nav-link">
                            {" "}
                            Careers
                          </a>
                          {/* </Link> */}
                        </li>

                        <li
                          className={
                            loc == "/contact" ? "nav-item active" : "nav-item"
                          }
                        >
                          {/* <Link href="/contact"> */}
                          <a href="/contact" className="nav-link">
                            Contact Us
                          </a>
                          {/* </Link> */}
                        </li>

                        <div className="nav-product">
                          <a
                            className="nav-product-btn d-flex"
                            onClick={handleClicks}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#proo"
                          >
                            {/* <span className="navbar-light navbar-toggler-icon "></span> */}
                            <span className="menu2">
                              <AiOutlineMenu size={25} />
                            </span>
                            <span className="menu1">
                              <MdMenu size={25} />
                            </span>{" "}
                            Products
                          </a>
                        </div>
                        <div ref={ref}
                          className={
                            clicks
                              ? "navbar-collapse collapse  justify-content-end show"
                              : "navbar-collapse collapse   justify-content-end"
                          }
                          id="proo"
                        >
                          <div className="pro p-5">
                            <div className="row nav-product-row mb-4">
                              {product?.map((datas) => (
                                <p
                                  className="col-lg-4 col-12 pt-2  pb-4 mb-4 border-bottom"
                                  key={datas.id}
                                >
                                  <a
                                    className="nav-product-list"
                                    href={
                                      "/product-detail/" +
                                      datas?.attributes?.Slug
                                    }
                                  >
                                    {datas?.attributes?.Title}
                                  </a>
                                </p>
                              ))}
                            </div>

                            <div className="row nav-product-row-last mb-2">
                              <p className="col-4 col-lg-4 head-viewallpro-dom"></p>
                              <a
                                href="/products"
                                type="button"
                                className="col-lg-4  btn-products text-align-left align-content-left nav-product-list"
                              >
                                View All Products
                              </a>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </nav>
              </header>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}
