import React, {useState, useEffect} from "react";
import ContainerDefault from "../components/Layout/ContainerDefault";
import Banner from "../components/Shared/Banner/DefaultBanner";
import Since from "../components/Shared/About/Since";
import axios from "axios";
import Profile from "../components/Shared/About/Profile";
import QualityAssurance from "../components/Shared/About/QualityAssurance";
import TrinityHoldingsSubsidiaries from "../components/Shared/About/TrinityHoldingsSubsidiaries";
import WordsfromtheCEO from "../components/Shared/About/WordsfromtheCEO";
import OurTeam from "../components/Shared/About/OurTeam";
import OurClients from "../components/Shared/About/OurClients";
function About() {

  const [abouts, setAbout] = useState([]);


  useEffect(() => {

    (async () => {
      const aboutss = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/about?populate[0]=About.Image&populate[1]=Profile.Image&populate[2]=Solution.Image&populate[3]=Quality.Image&populate[4]=Trinity.Image&populate[5]=Words.Image&populate[6]=Team.Image&populate[7]=BannerImage"
        )
        .then((data) => {
          setAbout(data.data.data.attributes);
        });
        
    })();
  }, []);

  const aboutus = {about: "about"}
  return (
    <div>
      <ContainerDefault>


        <Banner  content={abouts?.BannerContent} banimg={process.env.REACT_APP_IMAGE_BASE_URL + abouts?.BannerImage?.data?.attributes?.url}/>
        <Since Since={abouts}/>
        <Profile Profile={abouts}/>
        <QualityAssurance Quality={abouts}/>
        <TrinityHoldingsSubsidiaries Trinity={abouts} />
        {/* <WordsfromtheCEO Words={abouts}/> */}
        {/* <OurTeam Team={abouts}/> */}
        {/* <OurClients /> */}




      </ContainerDefault>
    </div>
  );
}

export default About;
