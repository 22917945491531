import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContainerDefault from "../components/Layout/ContainerDefault";
import ClientBanner from '../components/Shared/Banner/ClientBanner';
import Clientele from '../components/Shared/OurClients/Clientele'
function OurClients() {
    const [client, setClient] = useState([]);

    useEffect(() => {
  
      (async () => {
        const clients = await axios
          .get(
            process.env.REACT_APP_API_BASE_URL + "/clients?populate[0]=Image"
          )
          .then((data) => {
            setClient(data.data.data);
          });
          
      })();
    }, []);
    console.log(client, "ss");
    const logo = [
        {id: 1, 
            image1: "../asset/images/Picture001.png",
            image2: "../asset/images/Picture1.png",
            image3: "../asset/images/Picture002.png",
            image4: "../asset/images/Picture2.png",
    
            image5: "../asset/images/Picture005.png",
            image6: "../asset/images/Picture006.png",
            image7: "../asset/images/Picture007.png",
            image8: "../asset/images/Picture001.png",


        image9: "../asset/images/Picture001.png",
        image10: "../asset/images/Picture1.png",
        image11: "../asset/images/Picture002.png",
        image12: "../asset/images/Picture2.png",

        image13: "../asset/images/Picture002.png",
        image14: "../asset/images/Picture2.png",
        image15: "../asset/images/Picture1.png",
        image16: "../asset/images/Picture001.png",
        }, 
    
        {id: 2, 
        
        image1: "../asset/images/Picture005.png",
        image2: "../asset/images/Picture006.png",
        image3: "../asset/images/Picture007.png",
        image4: "../asset/images/Picture001.png",
        
        image5: "../asset/images/Picture001.png",
        image6: "../asset/images/Picture1.png",
        image7: "../asset/images/Picture002.png",
        image8: "../asset/images/Picture2.png",

        
        image9: "../asset/images/Picture002.png",
        image10: "../asset/images/Picture2.png",
        image11: "../asset/images/Picture1.png",
        image12: "../asset/images/Picture001.png",

        image13: "../asset/images/Picture001.png",
        image14: "../asset/images/Picture1.png",
        image15: "../asset/images/Picture002.png",
        image16: "../asset/images/Picture2.png",
        }, 
    
        {id: 3, 
            image1: "../asset/images/Picture001.png",
            image2: "../asset/images/Picture1.png",
            image3: "../asset/images/Picture002.png",
            image4: "../asset/images/Picture2.png",
        },
    
    
        {id: 4, 
            image1: "../asset/images/Picture001.png",
            image2: "../asset/images/Picture1.png",
            image3: "../asset/images/Picture002.png",
            image4: "../asset/images/Picture2.png",
        }, 
    
        {id: 5, 
            image1: "../asset/images/Picture001.png",
            image2: "../asset/images/Picture1.png",
            image3: "../asset/images/Picture002.png",
            image4: "../asset/images/Picture2.png",
        }, 
    
        {id: 6, 
            image1: "../asset/images/Picture001.png",
            image2: "../asset/images/Picture1.png",
            image3: "../asset/images/Picture002.png",
            image4: "../asset/images/Picture2.png",
        },
   
    ];
    
      const [currentPage, setCurrentPage] = useState(1);
      const [postsPerPage, setPostPerPage] = useState(16);
      const [product, setProduct] = useState([]);
      
      //get current post
      const indexOfLastpost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastpost - postsPerPage;
      const currentPosts = client.slice(indexOfFirstPost, indexOfLastpost);
    
      const paginate = (number) => setCurrentPage(number);
      const nextPage = () => setCurrentPage(currentPage + 1);
    
      const previousPage = () => setCurrentPage(currentPage - 1);
      const ourClients = {title: "Clients"}
  return (
    <div>
        <ContainerDefault>

        <ClientBanner ClientsTitle={ourClients?.title}/>
        <Clientele clients={client}
                postsPerPage={postsPerPage}
                totalPost={client}
                paginate={paginate}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}/>

        </ContainerDefault>

    </div>
  )
}

export default OurClients