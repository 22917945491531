// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  margin-bottom: 16px;
}
.progress__icon {
  display: flex;
}
.progress__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex-basis: 100%;
  height: 100%;
  padding: 10px 0 0 16px;
}
.progress__content__1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
}
.progress__content__2 {
  height: 2px;
  margin-bottom: 4px;
  position: relative;
  width: 100%;
  height: 2px;
  background: rgba(237, 102, 95, 0.5);
}
.progress__content__2__bar {
  z-index: 1;
  position: absolute;
  height: 2px;
  left: 0;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Progress/progress.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AACJ;AACI;EACE,aAAA;AACN;AAEI;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,uBAAA;EACA,gBAAA;EACA,YAAA;EACA,sBAAA;AAAN;AAEM;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AAAR;AAGM;EACE,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,mCAAA;AADR;AAGQ;EACE,UAAA;EACA,kBAAA;EACA,WAAA;EACA,OAAA;EACA,MAAA;AADV","sourcesContent":[".progress {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 100%;\r\n    height: 54px;\r\n    margin-bottom: 16px;\r\n  \r\n    &__icon {\r\n      display: flex;\r\n    }\r\n  \r\n    &__content {\r\n      display: flex;\r\n      flex-direction: column;\r\n      justify-content: flex-end;\r\n      align-items: flex-start;\r\n      flex-basis: 100%;\r\n      height: 100%;\r\n      padding: 10px 0 0 16px;\r\n  \r\n      &__1 {\r\n        display: flex;\r\n        justify-content: space-between;\r\n        align-items: center;\r\n        width: 100%;\r\n        margin-bottom: 14px;\r\n      }\r\n  \r\n      &__2 {\r\n        height: 2px;\r\n        margin-bottom: 4px;\r\n        position: relative;\r\n        width: 100%;\r\n        height: 2px;\r\n        background: rgba($color: #ed665f, $alpha: 0.5);\r\n  \r\n        &__bar {\r\n          z-index: 1;\r\n          position: absolute;\r\n          height: 2px;\r\n          left: 0;\r\n          top: 0;\r\n        }\r\n      }\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
