/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import HtmlParser from "react-html-parser";
import ModalImage from "react-modal-image";


function MaterialsandApplications({ Materialcnt }) {
  const [showResults, setShowResults] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [product, setProduct] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const chatClick = () => {
    setShowChat(!showChat);
    showForm == true ? setShowForm(!showForm) : "";
    showResults == true ? setShowResults(!showResults) : "";
  };
  const handleClick = () => {
    setShowResults(!showResults);
    showForm == true ? setShowForm(!showForm) : "";
    showChat == true ? setShowChat(!showChat) : "";
  };
  const handleClicks = () => {
    setShowForm(!showForm);
    showResults == true ? setShowResults(!showResults) : "";
    showChat == true ? setShowChat(!showChat) : "";
  };
  useEffect(() => {
    (async () => {
      const homes = await axios
        .get(process.env.REACT_APP_API_BASE_URL + "/products?populate=*")
        .then((data) => {
          setProduct(data.data);
        });
    })();
  }, []);

  return (
    <div>
        <div className="MaterialsandApplications">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 p-0 MaterialsandApplications-first-col ">
                        <div className="d-flex flex-wrap justify-content-between MaterialsandApplications-col">
                            <div className="MaterialsandApplications-btn">
                            <a className={showResults ? "btn4" : "btn5"} onClick={handleClick}>Materials and Applications</a>
                            </div>


                            <div className="MaterialsandApplications-btn">
                            <a className={showForm ? "btn4" : "btn5"} onClick={handleClicks}>Features</a>
                            </div>

                            <div className="MaterialsandApplications-btn">
                            <a className={showChat ? "btn4" : "btn5"} onClick={chatClick}>Technical Specifications</a>
                            </div>
                        </div>
                        <>
                        {showResults ? (
                            <>
                        <div className="Materials">
                           {Materialcnt?.attributes?.Materials?(
                            <>
                            <p className="Materials-sub">
                            {HtmlParser(Materialcnt?.attributes?.Materials)}
                            </p>
        
                            {Materialcnt?.attributes?.Image2?.data?.attributes?.url?(  
                                      
    <img className="py-4" src={process.env.REACT_APP_IMAGE_BASE_URL + Materialcnt?.attributes?.Image2?.data?.attributes?.url}/>
    ):("")}</> ) : (<> {Materialcnt?.attributes?.Image2?.data?.attributes?.url?(  
                                      
        <img className="py-4" src={process.env.REACT_APP_IMAGE_BASE_URL + Materialcnt?.attributes?.Image2?.data?.attributes?.url}/>
        ):(<p className="text-center">No data available</p>)}</>)}


    
                        </div>
                        </>
                        ) : ("")}
                       </>
                       <>

{showForm ? (   <>
                        <div className="Materials feature-cnt">
                            {Materialcnt?.attributes?.Features[0]?(
                            <div className="row fea">
                            {Materialcnt?.attributes?.Features?.map((datas) => (    
                            <div className="col-md-6 row feature py-2 my-2">
                                <div className="col-md-5 im" key={datas?.id}>
                                <ModalImage hideZoom={true} hideDownload={true}  small={process.env.REACT_APP_IMAGE_BASE_URL + datas?.Image?.data?.attributes?.url} large={process.env.REACT_APP_IMAGE_BASE_URL + datas?.Image?.data?.attributes?.url} width="100px" height="50px" />
                                </div>
                                <div className="col-md-7"><p className="pcnt">{datas?.Title}</p>
                                <p className="pcnt2">{datas?.Subtitle}</p>
                                </div>
                            </div>
  ))}
                            </div>
                            ):(<> {Materialcnt?.attributes?.Rfeatures?(<>
                            {HtmlParser(Materialcnt?.attributes?.Rfeatures)}</>):(<p className="text-center">No data available</p>)}
                            </>)}
                        </div>
                        </>) : ("")}
                        </>
                    
                    <>
                    {showChat ? (   <>
                    {Materialcnt?.attributes?.Rspecification?(
                        <div className="Materials">
                            {HtmlParser(Materialcnt?.attributes?.Rspecification)}
    {Materialcnt?.attributes?.Graph?.data?.attributes?.url?(         
    <img className="py-4" src={process.env.REACT_APP_IMAGE_BASE_URL + Materialcnt?.attributes?.Graph?.data?.attributes?.url}/>
    ):("")}
                        </div>):(<p className="text-center mt-5 pt-5">No data available</p>)}
          
                        </>) : ("")}
                        </>
                    </div>
                    

                    <div className="col-md-4 MaterialsandApplications-col2 ">
                            <div className='row'>
                                <div className='MaterialsandApplications-div '>
                                    <div className=''>
                                        <p className='MaterialsandApplications-title txt19'>Other Related Products</p>
                                    </div>
                                    <div className='MaterialsandApplications-sub-div'>
                                        
                                    {product?.data?.slice(0, 12)?.map((datas) =>(
                                    <p className='RelatedProducts-sub txt-8' key={datas?.id}><a href={"/product-detail/" + datas?.attributes?.Slug}>{datas?.attributes?.Title}</a> </p>
                                    ))}
                                        
                                    
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
              </div>

            </div>

  
          </div>

  );
}

export default MaterialsandApplications;
