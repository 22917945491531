import React, { useEffect, useRef, useState } from "react";
import Star from './star';
import { api } from "../../../lib/api";
import Captcha from '../../common/Captcha';
import validator from "validator";
import { useForm } from "react-hook-form";


function Feedback() {


        
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // name: "",
      // project: "",
    },
  });
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  const onSubmit = (userData) => {
    const data = {
      data: userData,
    };
    data.data.title = "Feedback";

    let nameFormat = /^[^-\s][a-zA-Z_\s-.]+$/g;

    if (!data.data.Name.match(nameFormat)) {
      setError("Enter a Valid Name");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

 

    let numberFormat = /^[0-9]\d{9,11}$/;
    // let isMobile = validator.isMobilePhone(data.data.mobile);
    if (!data.data.Phone.match(numberFormat)) {
      setError("Enter a Valid Mobile Number");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

    if (!validator.isEmail(data.data.Email)) {
      setError("Enter a Valid Email");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

    api
      .post("/feedbacks", data)
      .then(function (response) {
        if (response) {
          document.getElementById("contact-form").reset();
          setMessage("Thank you for Contacting Us");
          window.location.reload();
          setTimeout(function () {
            setMessage(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div>
        <div className='Feedback'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-4 p-0'>
                        <img className='Feedback-img' src='/asset/images/handsome-business-man.png'/>
                        <img className='Feedback-img1' src='/asset/images/handsome-business-man-engineer-hard-hat-building1.png'/>
                    </div>
                    <div className='col-md-8 '>
                        <div className='Feedback-form'>

                            <p className='txt2 m-0'>Feedback Form</p>
                            <form   onSubmit={handleSubmit(onSubmit)}  id="contact-form">
                                <div className='row'>
                                    <div className='form-group col-md-6'>
                                        <label>Name</label>
                                        <input
                  label={"Full Name"}
                  className={"form-control"}
                  {...register("Name", { required: true })}
                />
                {errors.Name && (
                  <span className="errors">This field is required</span>
                )}        
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label>Email</label>
                                        <input
                  label={"Email"}
                  className={"form-control"}
                  {...register("Email", { required: true })}
                />
                {errors.Email && (
                  <span className="errors">This field is required</span>
                )}                                   
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label>Company</label>
                                        <input
                  label={"Company"}
                  className={"form-control"}
                  {...register("Company", { required: true })}
                />
                {errors.Company && (
                  <span className="errors">This field is required</span>
                )}                 
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label>Phone Number</label>
                                        <input
                  label={"Mobile"}
                  className={"form-control"}
                  {...register("Phone", { required: true })}
                />
                {errors.Phone && (
                  <span className="errors">This field is required</span>
                )}                            
                                    </div>

                                    <div className='form-group col-md-12'>
                                        <label>Comments</label>
                                        <textarea
                  label={"Comments"}
                  className={"form-control"}
                  {...register("Comments", { required: true })}
                />
                {errors.Comments && (
                  <span className="errors">This field is required</span>
                )}     
                                    </div>

                                    <div className='form-group col-md-6'>
                                        <label>Rate us</label>
                                        <Star/>                                   
                                    
                                    </div>
                                    <div className='form-group col-md-6 '>
                                        <label>Verification</label>
                                            <Captcha/>                              
                                    
                                    </div>
                                    {message ? <div className="success-msg">{message}</div> : null}
              {error ? <div className="error-msg">{error}</div> : null}
                                    <div className='form-group col-md-12'>
                                        <div className='sendbtn'>
                                            <button  type="submit" className='btn4' >Send Feedback</button>
                                        </div>                             
                                    
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Feedback