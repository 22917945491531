import React from 'react'

function Development({Solutions}) {
    return (
        <div>
            <div className="ConsultingDesign" id="production">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 p-0 d-flex align-items-center">
                            <div>
                                <p className='ConsultingDesign-title txt2'>{Solutions?.Solution6?.Title}</p>
                                <p className='ConsultingDesign-sub txt3'>
                                {Solutions?.Solution6?.Content}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 p-0 ConsultingDesign-image-con d-flex align-items-center">
                            <img className='ConsultingDesign-image imgs' src={process.env.REACT_APP_IMAGE_BASE_URL + Solutions?.Solution6?.Image?.data?.attributes?.url} alt='ConsultingDesign-image' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="DewateringTechniques" id="development">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 p-0 d-flex align-items-center">
                        <img className='DewateringTechniques-image imgs' src={process.env.REACT_APP_IMAGE_BASE_URL + Solutions?.Solution7?.Image?.data?.attributes?.url} alt='DewateringTechniques-image' />
                    </div>
                    <div className="col-md-7 DewateringTechniques-txt d-flex align-items-center">
                    <div>
                            <p className='ConsultingDesign-title txt2'>{Solutions?.Solution7?.Title}</p>
                            <p className='ConsultingDesign-sub txt3'>
                            {Solutions?.Solution7?.Content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="ConsultingDesign" id="research">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 p-0 d-flex align-items-center">
                            <div>
                                <p className='ConsultingDesign-title txt2'>{Solutions?.Solution8?.Title}</p>
                                <p className='ConsultingDesign-sub txt3'>
                                {Solutions?.Solution8?.Content}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 p-0 ConsultingDesign-image-con d-flex align-items-center">
                            <img className='ConsultingDesign-image imgs' src={process.env.REACT_APP_IMAGE_BASE_URL + Solutions?.Solution8?.Image?.data?.attributes?.url} alt='ConsultingDesign-image' />
                        </div>
                    </div>
                </div>
            </div>

        </div>
  )
}

export default Development