import React, {useState} from 'react';
import ModalImage from "react-modal-image";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


function GalleryImages({gallery, index=0}) {
    const [Limit, setLimit] = useState(6)
    const load = gallery?.length;
      const [photoIndex, setPhotoIndex] = useState(0);
      const [isOpen, setIsOpen] = useState(false);
     
      const openLightbox = (test) => {
       
        setPhotoIndex(test);
        setIsOpen(true);
      };
  return (
    <div>
        <div className='GalleryImages'>
            <div className='container'>
                <div className='row'> 

                    <div class="grid-wrapper">
                        {gallery?.slice(index, Limit).map((datas, test) => (
                        <div key={datas.id}>
                            <img onClick={() => openLightbox(test)} src={process.env.REACT_APP_IMAGE_BASE_URL + datas?.attributes?.Image?.data?.attributes?.url}   alt="ss" />
                        </div>
                        ))}
                          {isOpen && (
        <Lightbox
        className="gimg"
        mainSrc={process.env.REACT_APP_IMAGE_BASE_URL + gallery[photoIndex].attributes?.Image?.data?.attributes?.url}
          nextSrc={gallery[(photoIndex + 1) % load]}
          prevSrc={gallery[(photoIndex + load - 1) % load]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + load - 1) % load)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % load)
          }

          
        />
      )}
                    </div>
                </div>
                
                <div className='row'>
                
                    <div className='col-md-12 GalleryBtn d-flex justify-content-center '>
                 {Limit< load ?(
                    <a onClick={() => setLimit(Limit + 6)} className="btn btn-color btn-l-r mb-3 ">Load More</a>) : ("")}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}



export default GalleryImages