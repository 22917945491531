import React, { useEffect, useRef, useState } from "react";
import Captcha from '../../common/Captcha';
import { api } from "../../../lib/api";
import {BsDownload} from 'react-icons/bs';
import validator from "validator";
import { useForm } from "react-hook-form";

function Enquiry() {

    
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // name: "",
      // project: "",
    },
  });
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  const onSubmit = (userData) => {
    const data = {
      data: userData,
    };
    data.data.title = "Enquiry";

    let nameFormat = /^[^-\s][a-zA-Z_\s-.]+$/g;

    if (!data.data.Name.match(nameFormat)) {
      setError("Enter a Valid Name");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

 

    let numberFormat = /^[0-9]\d{9,11}$/;
    // let isMobile = validator.isMobilePhone(data.data.mobile);
    if (!data.data.Phone.match(numberFormat)) {
      setError("Enter a Valid Mobile Number");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

    if (!validator.isEmail(data.data.Email)) {
      setError("Enter a Valid Email");
      setTimeout(function () {
        setError(false);
      }, 5000);
      return;
    }

    api
      .post("/enquiries", data)
      .then(function (response) {
        if (response) {
          document.getElementById("contact-form").reset();
          setMessage("Thank you for Contacting Us");
          window.location.reload();
          setTimeout(function () {
            setMessage(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div>
        <div className='Enquiry'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-5 p-0'>
                        <img className='Enquiry-img' src='/asset/images/double-exposure-image-business-finance (1)@2x.png'/>
                        <img className='Enquiry-img1' src='/asset/images/double-exposure-image-business-finance (1).png'/>
                    </div>
                    <div className='col-md-7 p-0 '>
                        <div className='Enquiry-form'>

                            <p className='txt2 m-0'>Enquiry Form</p>
                            <div className='Enquiry-form-div'>
                                <form  onSubmit={handleSubmit(onSubmit)}  id="contact-form">
                                    <div className='row'>
                                        <div className='form-group col-md-6'>
                                            <label>Name</label>
                                            <input
                  label={"Full Name"}
                  className={"form-control"}
                  {...register("Name", { required: true })}
                />
                {errors.Name && (
                  <span className="errors">This field is required</span>
                )}        
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>Email</label>
                                            <input
                  label={"Email"}
                  className={"form-control"}
                  {...register("Email", { required: true })}
                />
                {errors.Email && (
                  <span className="errors">This field is required</span>
                )}                                                         </div>
                                        <div className='form-group col-md-6'>
                                            <label>Company</label>
                                            <input
                  label={"Company"}
                  className={"form-control"}
                  {...register("Company", { required: true })}
                />
                {errors.Company && (
                  <span className="errors">This field is required</span>
                )}                 
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>Subject</label>
                                            <input
                  label={"Subject"}
                  className={"form-control"}
                  {...register("Subject", { required: true })}
                />
                {errors.Subject && (
                  <span className="errors">This field is required</span>
                )}     
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>Phone Number</label>
                                            <input
                  label={"Mobile"}
                  className={"form-control"}
                  {...register("Phone", { required: true })}
                />
                {errors.Phone && (
                  <span className="errors">This field is required</span>
                )}                            
                                        </div>

                                        <div className='form-group col-md-12'>
                                            <label>Comments</label>
                                            <textarea
                  label={"Comments"}
                  className={"form-control"}
                  {...register("Comments", { required: true })}
                />
                {errors.Comments && (
                  <span className="errors">This field is required</span>
                )}     
                                        </div>

                                       
                                        <div className='form-group col-md-10'>
                                            <label>Verification</label>
                                                <Captcha/>                              
                                        
                                        </div>
                                        
                                {message ? <div className="success-msg">{message}</div> : null}
              {error ? <div className="error-msg">{error}</div> : null}
                                        <div className='form-group col-md-12 Enq-Btns  d-flex    align-items-center'>
                                            <div className='sendbtn'>
                                                <button type="submit" className='btn4' >Send Enquiry</button>
                                            </div>  
                                            <div className='Dowbtn '>
                                                <button className='btn6' ><BsDownload size={25}/>&nbsp;&nbsp;Download Brochure</button>
                                            </div>                            
                                        
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Enquiry