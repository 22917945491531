import React from 'react'

function Hire({Solutions}) {
  return (
    <div>
        <div className="Hire" id='hire'>
            <div className="container">
                <div className="row">
                    <div className="col-md-5 p-0 d-flex align-items-center">
                        <div>
                            <p className='Hire-title txt2'>{Solutions?.Solution2?.Title}</p>
                            <p className='Hire-sub txt3'>
                            {Solutions?.Solution2?.Content}
                            </p>
                        </div>
                        

                    </div>
                    <div className="col-md-7 Hire-image-con d-flex align-items-center">
                        <img className='Hire-image' src={process.env.REACT_APP_IMAGE_BASE_URL + Solutions?.Solution2?.Image?.data?.attributes?.url} alt='Hire-image' />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hire