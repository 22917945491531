import React from 'react'

function MainNews({Mnews, limit = 2, index = 0, lim = 3}) {
  return (
    <div>
        <div className='MainNews'>
            <div className='container'>
                <div className='row'>
                    <p className='txt2 p-0'>News from around the globe</p>
                    <div className='MainNews-news'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-8   '>
                                {Mnews?.slice(index, limit).map((datas) =>  (
                                <div className='row News2' key={datas.key}>
                                    <div className='col-md-6  p-0'>
                                        <img className='MainNews-news-img' src={process.env.REACT_APP_IMAGE_BASE_URL + datas?.attributes?.Image?.data?.attributes?.url}/>
                                    </div>
                                    <div className='col-md-6 p-0 MainNews-news-con'>
                                        <div className='p-0 MainNews-news-con-news'>
                                            <p className='txt19 MainNews-news-con-news-title'>{datas?.attributes?.Title}</p>
                                            <p className='txt22 MainNews-news-con-news-det' >
                                            {datas?.attributes?.ShortDescription}
                                            </p>
                                            <p className='NewsDate'>{datas?.attributes?.Date}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                                ))}

                            </div>
                            <div className='col-md-12 col-lg-4 MainNews-Related'>
                                <div className='row'>
                                    <div className='MainNews-div '>
                                        <div className=''>
                                            <p className='MainNews-title txt19'>Related News</p>
                                        </div>
                                        <div className='MainNews-sub-div'>
                                        {Mnews?.slice(index, lim).map((datas) =>   (  
                                            <p className='MainNews-sub txt8' key={datas.id}>{datas?.attributes?.Title}
                                            <br></br>
                                            <span className='txt20'>{datas?.attributes?.Date}</span>
                                            
                                            </p>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MainNews