import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContainerDefault from "../components/Layout/ContainerDefault";
import DefaultBanner from '../components/Shared/Banner/DefaultBanner';
import MainNews from '../components/Shared/News/MainNews';
import SubNews from '../components/Shared/News/SubNews';
import Pagination from '../components/Shared/News/Pagination';
function News() {
  const [lnews, setNews] = useState([]);
  const [banner, setBanner] = useState([]);

  useEffect(() => {

    (async () => {
      const aboutss = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/news?populate[0]=Thumbnail&populate[1]=Image"
        )
        .then((data) => {
          setNews(data.data.data);
        });
        
    })();
  }, []);

  useEffect(() => {

    (async () => {
      const bannerss = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/news-page?populate[0]=BannerImage"
        )
        .then((data) => {
          setBanner(data.data.data.attributes);
        });
        
    })();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(3);
  const [product, setProduct] = useState([]);
  
  //get current post
  const indexOfLastpost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastpost - postsPerPage;
  const currentPosts = lnews.slice(indexOfFirstPost, indexOfLastpost);

  const paginate = (number) => setCurrentPage(number);
  const nextPage = () => setCurrentPage(currentPage + 1);

  const previousPage = () => setCurrentPage(currentPage - 1);
  const Bnews = {title: "news"}
  return (
    <div>
        <ContainerDefault>
            <DefaultBanner  content={banner?.BannerContent} banimg={process.env.REACT_APP_IMAGE_BASE_URL + banner?.BannerImage?.data?.attributes?.url}/>      
            <MainNews Mnews={lnews}/>
            <SubNews Mnews={currentPosts}
                postsPerPage={postsPerPage}
                totalPost={lnews}
                paginate={paginate}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}/>
        </ContainerDefault>
    </div>
  )
}

export default News