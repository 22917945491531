import React from "react";
import HtmlParser from "react-html-parser";

function WordsfromtheCEO({Words}) {

  return (
    <div>
      <div className="WordsfromtheCEO">
        <div className="container">
        <div className="row">
          <div className="col-md-5 WordsfromtheCEO-img-con">
            <img
              className="img-responsive WordsfromtheCEO-img "
              src={process.env.REACT_APP_IMAGE_BASE_URL + Words?.Words?.Image?.data?.attributes?.url}
            />
          </div>
          <div className="col-md-7 WordsfromtheCEO-con-det">
            <div className="WordsfromtheCEO-con">
              <p className="txt2">
              Words from the MD
              </p>
              <p className="txt3">
              {HtmlParser(Words?.Words?.Content)}
              </p>
              <br></br><br></br>
              <p className="txt14"> {HtmlParser(Words?.Words?.Title)}</p>
              <p className="txt">Have 35+ years of experience in industry</p>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
  );
}

export default WordsfromtheCEO;
