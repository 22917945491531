import React from "react";
import Head from "next/head";
import Header from "../common/Header";
import Footer from "../common/Footer";
function ContainerDefault({ children }) {
  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        <link rel="icon" href="/asset/images/Logo.svg" />
        <title>Oasis Pumps</title>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </Head>
      <Header />
      {children}
      <Footer />
    </div>
  );
}

export default ContainerDefault;
