
import React, { useEffect, useRef, useState } from "react";
import "react-client-captcha/dist/index.css";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';


function Captcha() {
  
    const captchaRef1 = useRef(null);
    // const handleCaptcha1 = (e) => {
    //   const captcha = captchaRef1.current.value;
    //   if (validateCaptcha(captcha)) {
    //     alert("true");
    //   } else {
    //     alert("false");
    //   }
    // };
    // loadCaptchaEnginge(6,'red');
    useEffect(() => {
      loadCaptchaEnginge(6);
    });

    
  return (
    <div>
        <div className='d-flex captcha align-items-end'>
            <input className='form-control ' ref={captchaRef1} placeholder='Verify Captcha' type="text" /> 
            <LoadCanvasTemplateNoReload  />
        </div>
    </div>
  )
}

export default Captcha