import React from "react";
import HtmlParser from "react-html-parser";

function Since({ Since }) {
  return (
    <div>
      <div className="since">
        <div className="container">
          <div className="row since-row-p5">
            <div className="since-top-link d-flex">
              <div className="since-top-link-text d-flex flex-wrap col-md-12">
                <div className="col-6 col-sm-4 col-md-3 since-top-links">
                  <a href="#oasis-pumps">Oasis Pumps</a>
                </div>
                <div className="col-6 col-sm-4 col-md-3 since-top-links">
                  <a href="#company-profile">Company Profile</a>
                </div>
                <div className="col-6 col-sm-4 col-md-3 since-top-links">
                  <a href="#quality-assurance">Quality Assurance</a>
                </div>
                <div className="col-6 col-sm-4 col-md-3 since-top-links">
                  <a href="#trinity-holdings">Trinity Holdings</a>
                </div>
                {/* <div className="col-6 col-sm-4 col-md-2 since-top-links">
                  <a href="#our-team">Our Team</a>
                </div>
                <div className="col-6 col-sm-4 col-md-2 since-top-links">
                  <a href="#our-clients">Our Clients</a>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row since-con" id="oasis-pumps">
            <div className="col-md-5 since-img p-0">
              <img
                className=""
                src={
                  process.env.REACT_APP_IMAGE_BASE_URL +
                  Since?.About?.Image?.data?.attributes?.url
                }
                alt="since-img"
              />
            </div>
            <div className="col-md-7  ">
              <p className="since-title "> {HtmlParser(Since?.About?.Title)}</p>
              <p className="since-desc ">
                {" "}
                {HtmlParser(Since?.About?.Content)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Since;
