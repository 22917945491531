import React from 'react'

function TurnkeyProjects({Solutions}) {
  return (
    <div>
        <div className="TurnkeyProjects" id="turnkey-projects">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 p-0">
                        <img className='TurnkeyProjects-image' src={process.env.REACT_APP_IMAGE_BASE_URL + Solutions?.Solution3?.Image?.data?.attributes?.url} alt='TurnkeyProjects-image' />
                    </div>

                    <div className="col-md-6 p-0 d-flex align-items-center TurnkeyProjects-con">
                        <div>
                            <p className='TurnkeyProjects-title txt2'>{Solutions?.Solution3?.Title}</p>
                            <p className='TurnkeyProjects-sub txt3'>
                            {Solutions?.Solution3?.Content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TurnkeyProjects