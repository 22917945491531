import React, { lazy, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import { fetchAPI } from "../lib/api";
import "bootstrap/dist/css/bootstrap.min.css";
//import Header from "../components/common/Header";
//import SimpleSlider from "../components/Shared/Home/Simple-Slide";
//import AboutUs from "../components/Shared/Home/AboutUs";
//import DevelopmentAndSupport from "../components/Shared/Home/DevelopmentAndSupport";
//import OurServices from "../components/Shared/Home/OurServices";
//import OurProducts from "../components/Shared/Home/OurProducts";
//import LatestNews from "../components/Shared/Home/LatestNews";
//import Footer from "../components/common/Footer";
import "../styles/_fonts.scss";
//import ContainerDefault from "../components/Layout/ContainerDefault";

const SimpleSlider = lazy(() => import('../components/Shared/Home/Simple-Slide'));
const AboutUs = lazy(() => import('../components/Shared/Home/AboutUs'));
const DevelopmentAndSupport = lazy(() => import('../components/Shared/Home/DevelopmentAndSupport'));
const OurServices = lazy(() => import('../components/Shared/Home/OurServices'));
const OurProducts = lazy(() => import('../components/Shared/Home/OurProducts'));
const ContainerDefault = lazy(() => import('../components/Layout/ContainerDefault'));


export default function Home(Home) {
  const [home, setHome] = useState([]);
  const [product, setProduct] = useState([]);
  const [latestnews, setLatestnews] = useState([]);

  useEffect(() => {
    (async () => {
      const homes = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            "/home?populate[0]=Banner.Image&populate[1]=About.Image&populate[2]=Values&populate[3]=Development.Image1&populate[4]=Development.Image2&populate[5]=Development.Image3&populate[6]=Contact.Image&populate[7]=Banner.File"
        )
        .then((data) => {
          setHome(data.data.data.attributes);
        });
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const homes = await axios
        .get(process.env.REACT_APP_API_BASE_URL + "/products?populate[0]=Image")
        .then((data) => {
          setProduct(data.data);
        });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const homes = await axios
        .get(process.env.REACT_APP_API_BASE_URL + "/news?populate[0]=Thumbnail")
        .then((data) => {
          setLatestnews(data.data);
        });
    })();
  }, []);

  return (
    <React.StrictMode>
      <ContainerDefault>
      

        <SimpleSlider Banner={home.Banner} />
        <AboutUs About={home.About} Values={home.Values} />
        <DevelopmentAndSupport Development={home.Development} />
        <OurServices Services={home} />
        <OurProducts Products={home} ListProduct={product} />
        {/* <LatestNews News={home} LatestNews={latestnews} Contact={home} /> */}
      </ContainerDefault>
    </React.StrictMode>
  );
}

// export async function getServerSideProps(ctx) {
//   const [Home] = await Promise.all([
//     fetchAPI(
//       "/home?populate[0]=Banner.Image"
//       ),
//   ]);

// console.log(Home, 'ss');
//   return {
//     props: {
//       Home: Home.data.attributes
//     },
//   };
// }
