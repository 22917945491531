import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ContainerDefault from "../../components/Layout/ContainerDefault";
import { fetchAPI } from "../../lib/api";
import ProductSliderContent from "../../components/Shared/Products–Detailed/ProductSliderContent";
import MaterialsandApplications from "../../components/Shared/Products–Detailed/MaterialsandApplications";
export default  function ProductsDetailed({Pro}) {
  
  let location = useLocation();
  let loc = location.pathname;
  const locs = loc.split("/product-detail/")
    const [products, setProduct] = useState([]);



    useEffect(() => {

      (async () => {
        const homes = await axios
          .get(
            process.env.REACT_APP_API_BASE_URL + "/products?filters[Slug][$eq] =" + locs[1] + "&populate[0]=Image&populate[1]=Gallery&populate[2]=Features.Image&populate[3]=Specification&populate[4]=Graph&populate[5]=Image2&populate[6]=Image3"
          )
          .then((data) => {
            setProduct(data.data.data);
          });
          
      })();
    }, []);

  return (
    <div>
        <ContainerDefault>
                
            <ProductSliderContent Slidercnt={products[0]}/>
            <MaterialsandApplications Materialcnt={products[0]}/>
        </ContainerDefault>
    </div>
  );
  
}


export async function getServerSideProps(contest) {
  const Pro = await Promise.all([
    fetchAPI(`/products?populate[0]=Image`),
  ]);



  return {
    props: {
      Pro,
    },
  };
}




