import React from 'react'

function ProductsBanner() {
  return (
    <div>

            <div className="ProductsBanner">
                <div className="row">
                    <div className="col-md-5 p-0 ProductsBanner-text d-flex align-items-center">
                      <div className="ProductsBanner-txt ">
                        <p className="ProductsBanner-text-title txt2">Best quality pumps in UAE</p>
                        <p className="ProductsBanner-text-sub txt18">
                          Oasis capability extends to manufacturing custom designed pumps fitted with 
                          specialist materials to match specific customer requirements. Oasis has a diverse
                          variety of pumps, ranging from 3” to 14”.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-7 p-0 d-flex">
                      <img className="ProductsBanner-image" src="/asset/images/Bproducts.jpg" alt="banner" />
                    </div>
                </div>
                
            </div>
    </div>
  )
}

export default ProductsBanner