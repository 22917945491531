import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdEmail } from "react-icons/md";
import $ from 'jquery';
import { BsTelephoneFill } from "react-icons/bs";
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

function Footer() {
  const [footer, setFooter] = useState([]);

  useEffect(() => {
    (async () => {
      const homes = await axios
        .get(process.env.REACT_APP_API_BASE_URL + "/footer?populate[0]=*")
        .then((data) => {
          setFooter(data.data.data.attributes);
        });
    })();
  }, []);

  useEffect(() => {
    $('html, body').hide();

    if (window.location.hash) {
      setTimeout(function() {
        $('html, body').scrollTop(0).show();
        $('html, body').animate({
          scrollTop: $(window.location.hash).offset().top
        }, 500);
      }, 0);
    } else {
      $('html, body').show();
    }
  }, []);

  return (
    <div>
        <div className="whatsapp">
          <a
            href="https://api.whatsapp.com/send?phone=+97143335118&amp;text=Hi"
            target="_blank"
          >
            <img src="/asset/images/whatsapp.svg" width="60px" />
          </a>
        </div>
      <footer className="position-relative">
        <div className="footer-top">
          <div className="container">
            <div className="row ">
              <div className="col-sm-12 col-md-4 p-0 foot-logo">
                <a href="/">
                  <img
                    src="/asset/images/logo-3.png"
                    alt="logo"
                    className="logo"
                  />
                </a>
                <p>
                  <MdEmail className="me-2" style={{ color: "#002B85" }} />
                  {footer.Email}
                </p>
                <p>
                  <BsTelephoneFill
                    className="me-2"
                    style={{ color: "#002B85" }}
                  />
                  {footer.PhoneNumber}
                </p>
              </div>

              <div className=" col-sm-4 col-md-2 pe-md-0  col-lg-2  ">
                <div className="footer-title">Quick Links</div>
                <ul className="list ">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/products">Products</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/news">News</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/career">Careers</a>
                  </li>
                </ul>
              </div>

              <div className="col-sm-4 pe-md-0 col-md-2 ">
                <div className="footer-title">
                  <a href="/about">About Us</a>
                </div>
                <ul className="list ">
                  <li>
                    <a href="/about">About Oasis </a>
                  </li>
                  <li>
                  <a href="/about#company-profile">Company Profile </a>
                  </li>
                  <li>
                  <a href="/about#quality-assurance">Quality Assurance </a>
                  </li>
                  <li>
                  <a href="/about#trinity-holdings">Trinity Holdings</a>
                  </li>
                </ul>
              </div>

              <div className="col-sm-4 col-md-4 ">
                <div className="footer-title">Address</div>
                <ul className="list ">{HtmlParser(footer.Address)}</ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="tail ">
            <div className="container">
              <div className="row tail-row ">
                <div className="col-md-10 col-lg-10">
                <ul className="p-0">
                  <li><a href="/contact"> Contact Us </a> </li>
                  <li><a href="/Enquiry"> Inquiries </a> </li>
                  <li> <a href="/FeedbackForm"> Feedback </a> </li>
                  <li> <a href="/gallery"> Gallery </a> </li>
                  <li><a href="/site-map"> Site Map </a> </li>
                  <li><a href="https://www.linkedin.com/company/oasis-pumps/" target="_blank"> <img src="/asset/images/linkedin.svg" width="20px"/> </a> </li>
                </ul>
                </div>
                <div className=" col-md-2 p-0 d-flex center justify-content-around tail-row-logo">
                 
                  {/* <p className=" footer-text2">All Rights Reserved</p> */}
                  <a href="http://trinityholdings.com/" target="_blank">
                  <img
                    className="footer-img col-md-2"
                    src="/asset/images/Image-1.png"
                    alt="footer-img"
                  /></a>
                </div>
                
              </div>
            
            </div>
          </div>
        </div>
        <div className="design">
        <div className="container">
        <div className="row">
             
             <div className="des col-md-6 col-lg-6">
               {/* <p>Designed and Developed by <a href="https://centresource.in/" target="_blank">Centre Source</a></p> */}
             </div>
             <div className="col-md-6 col-lg-6"> 
             <p className="footer-text"><b>
                   {footer?.Copyright}</b>
                   <span className="footer-text2"> All Rights Reserved</span>
                 </p></div>
             </div>
             </div>
             </div>
      </footer>
    </div>
  );
}

export default Footer;
