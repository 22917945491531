import React from "react";
import HtmlParser from "react-html-parser";


function Profile({Profile}) {
  return (
    <div>
      <div className="profile-container" id="company-profile">
        <div className="container">
          <div className="row">
            {/* <img
              className="CompanyProfile-img"
              src="/asset/images/Rectangle.png"
            /> */}
            {/* <div className="CompanyProfile-img p-0"> */}
              <div className="CompanyProfile-img-overly p-0">

                <img className="CompanyProfile-img" src={process.env.REACT_APP_IMAGE_BASE_URL + Profile?.Profile?.Image?.data?.attributes?.url}/>

              </div>
            {/* </div> */}
            <div className="CompanyProfile-con">
              <p className="txt2"> {HtmlParser(Profile?.Profile?.Title)}</p>
              <p className="txt3">
              {HtmlParser(Profile?.Profile?.Content)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Com-Profile">
        <div className="container Com-Profile-con ">
          <div className="row">
            {Profile?.Solution?.map((datas) => (
            <div className="col-md-6 company-profile" key={datas.id}>
              <div className="d-flex align-items-center p-4">
                <img className="profile-icon" src={process.env.REACT_APP_IMAGE_BASE_URL + datas?.Image?.data?.attributes?.url} />
                <p className="txt13 profile-txt">
                  {datas?.Content}
                </p>
              </div>
            </div>
            )) }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
