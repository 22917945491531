import React from 'react'

function DewateringTechniques({Solutions}) {
  return (
    <div>
        <div className="DewateringTechniques" id="dewatering-techniques">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 p-0 d-flex align-items-center">
                        <img className='DewateringTechniques-image' src={process.env.REACT_APP_IMAGE_BASE_URL + Solutions?.Solution5?.Image?.data?.attributes?.url} alt='DewateringTechniques-image' />
                    </div>
                    <div className="col-md-7 DewateringTechniques-txt d-flex align-items-center">
                    <div>
                            <p className='ConsultingDesign-title txt2'>{Solutions?.Solution5?.Title}</p>
                            <p className='ConsultingDesign-sub txt3'>
                            {Solutions?.Solution5?.Content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DewateringTechniques