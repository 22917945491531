import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {BsArrowRight} from 'react-icons/bs';
function OurClients() {
  const [client, setClient] = useState([]);

  useEffect(() => {

    (async () => {
      const clients = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/clients?populate[0]=Image"
        )
        .then((data) => {
          setClient(data.data.data);
        });
        
    })();
  }, []);
  return (
    <div>
      <div className="OurClients" id="our-clients">
        <div className="OurClients-bg">
          <div className="container">
            <div className="OurClients-con">
              <div className="row">
                <p className="txt11 ">Our Clients</p>
              </div>
              <div className="row">
                <div className="OurClients-div">
                  <div className="m-5">
                    <div className="OurClients-logo-first-row d-flex flex-wrap p-3">
                      {client?.slice(0, 7)?.map((datas) => (
                      <div className='col-md-6 col-lg-3 p-3 OurClients-logo' key={datas.id}>
                        <img className='Clientslogo'src={process.env.REACT_APP_IMAGE_BASE_URL + datas?.attributes?.Image?.data?.attributes?.url}/>
                      </div>
                      ))}
                      <div className='col-md-6 col-lg-3 p-3 OurClients-logo '>
                      <a href='/OurClients' className="btn2 btn2-color btn2-l-r mt-4">View All Clients&nbsp;<BsArrowRight/></a>
                      </div>
                    
                    </div>



                    {/* <div className="OurClients-logo-first-row d-flex flex-wrap p-3">
                      <div className='col-md-6 col-lg-3 p-3 OurClients-logo '>
                        <img className='Clientslogo'src='/asset/images/Picture005.png'/>
                      </div>

                      <div className='col-md-6 col-lg-3 p-3 OurClients-logo '>
                        <img className='Clientslogo'src='/asset/images/Picture006.png'/>
                      </div>

                      <div className='col-md-6 col-lg-3 p-3 OurClients-logo '>
                        <img className='Clientslogo'src='/asset/images/Picture007.png'/>
                      </div>

                      <div className='col-md-6 col-lg-3 p-3 OurClients-logo '>
                      <a href='/OurClients' className="btn2 btn2-color btn2-l-r mt-5">View All Clients&nbsp;<BsArrowRight/></a>
                      </div>
                    </div> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    
  )
}

export default OurClients