import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ContainerDefault from "../components/Layout/ContainerDefault";
import Banner from "../components/Shared/Banner/ProductsBanner";
import ProductsList from "../components/Shared/Products/ProductsList";
function Products() {
  const [product, setProduct] = useState([]);
  const [lnews, setNews] = useState([]);


  useEffect(() => {

    (async () => {
      const homes = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/products?populate[0]=Image"
        )
        .then((data) => {
          setProduct(data.data);
        });
        
    })();
  }, []);

  useEffect(() => {

    (async () => {
      const aboutss = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/news?populate[0]=Thumbnail&populate[1]=Image"
        )
        .then((data) => {
          setNews(data.data.data);
        });
        
    })();
  }, []);
  return (
    <div>
      <ContainerDefault>
            <Banner/> 
            <ProductsList Productlist={product} Mnews={lnews}/>      

        </ContainerDefault>
    </div>
  )
}

export default Products