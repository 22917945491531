import React from 'react'

function ConsultingDesign({Solutions}) {
  return (
    <div>
        <div className="ConsultingDesign" id="consulting-design">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 p-0 d-flex align-items-center">
                        <div>
                            <p className='ConsultingDesign-title txt2'>{Solutions?.Solution4?.Title}</p>
                            <p className='ConsultingDesign-sub txt3'>
                            {Solutions?.Solution4?.Content}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ConsultingDesign-image-con d-flex align-items-center">
                        <img className='ConsultingDesign-image' src={process.env.REACT_APP_IMAGE_BASE_URL + Solutions?.Solution4?.Image?.data?.attributes?.url} alt='ConsultingDesign-image' />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ConsultingDesign