import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ContainerDefault from "../components/Layout/ContainerDefault";
import DefaultBanner from '../components/Shared/Banner/DefaultBanner';
import CurrentOpportunities from '../components/Shared/Career/CurrentOpportunities';
import Novacancy from '../components/Shared/Career/Novacancy';
import Script from "next/script";
import $ from 'jquery'; 

function Career() {
  const Career = {title: "Career"}
  const [career, setCareer] = useState([]);
  const [banner, setBanner] = useState([]);



  useEffect(() => {

    (async () => {
      const homes = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/careers?populate[0]=*"
        )
        .then((data) => {
          setCareer(data.data);
        });
        
    })();
  }, []);
  useEffect(() => {

    (async () => {
      const bannerss = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/career-page?populate[0]=BannerImage"
        )
        .then((data) => {
          setBanner(data.data.data.attributes);
        });
        
    })();
  }, []);
  return (
    <div>
      <Script src="http://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js" type="text/javascript"></Script>

      <Script type='text/javascript'>
    
        
      </Script>
        <ContainerDefault>
            <DefaultBanner content={banner?.BannerContent} banimg={process.env.REACT_APP_IMAGE_BASE_URL + banner?.BannerImage?.data?.attributes?.url}/>
            <CurrentOpportunities Car={career?.data}/>   
            <Novacancy/>
        </ContainerDefault>

    </div>
  )
}

export default Career