import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ContainerDefault from "../components/Layout/ContainerDefault";
import Banner from "../components/Shared/Banner/SolutionsBanner";
import CustomManufacturing from "../components/Shared/Solutions/CustomManufacturing";
import Hire from "../components/Shared/Solutions/Hire";
import TurnkeyProjects from "../components/Shared/Solutions/TurnkeyProjects";
import ConsultingDesign from "../components/Shared/Solutions/ConsultingDesign";
import DewateringTechniques from "../components/Shared/Solutions/DewateringTechniques";
import Development from "../components/Shared/Solutions/Development";

function Solutions() {
  const [solutions, setSolution] = useState([]);


  useEffect(() => {

    (async () => {
      const aboutss = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/solution?populate[0]=Solution1.Image&populate[1]=Solution2.Image&populate[2]=Solution3.Image&populate[3]=Solution4.Image&populate[4]=Solution5.Image&populate[5]=BannerImage&populate[6]=Solution6.Image&populate[7]=Solution7.Image&populate[8]=Solution8.Image"
        )
        .then((data) => {
          setSolution(data.data.data.attributes);
        });
        
    })();
  }, []);
  return (
    <div>
        <ContainerDefault>
            <Banner content={solutions?.BannerContent} banimg={process.env.REACT_APP_IMAGE_BASE_URL + solutions?.BannerImage?.data?.attributes?.url}/>
            <div className="Solutions-content">
            <CustomManufacturing Solutions={solutions} />
            <Hire Solutions={solutions}/>
            <TurnkeyProjects Solutions={solutions}/>
            <ConsultingDesign Solutions={solutions}/>
            <DewateringTechniques Solutions={solutions}/>
            <Development Solutions={solutions}/>
            </div>

        </ContainerDefault>

    </div>
  )
}

export default Solutions