import React, {useState} from 'react';
import Pagination from '../OurClients/Pagination'
function Clientele({index=0,clients,
    postsPerPage,
    totalPost,
    paginate,
    currentPage,
    previousPage,
    nextPage,
    setCurrentPage,}) {
        const [Limit, setLimit] = useState(12);
        const load = clients?.length;
  return (
    <div>
        <div className='Clientele'>
            <div className='container'>
                <div className='row'>
                    <p className='txt2'>Clientele</p>
                    <div className='clients '>
                        <div className='clients-list'>
                            <div className='row'>
                            {clients?.slice(index, Limit).map((value)=> (
                                <div className='clients-div col-12 col-sm-6 col-md-3'>
                                    <div className='clients-div-logo'>
                                        <img className='clients-div-logo-img' src={process.env.REACT_APP_IMAGE_BASE_URL + value?.attributes?.Image?.data?.attributes?.url}/>
                                    </div>
                                </div>
                                ))
                            }
                            </div>
                        </div>
                         
                  



                    {/* <Pagination
                    postPerPage={postsPerPage}
                    totalPosts={totalPost}
                    paginate={paginate}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    /> */}
                </div>
            </div>
            <div className='row'>
                
                <div className='col-md-12 GalleryBtn d-flex justify-content-center '>
             {Limit< load ?(
                <a onClick={() => setLimit(Limit + 8)} className="btn btn-color btn-l-r mb-3 ">Load More</a>) : ("")}
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}

export default Clientele