import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ContainerDefault from "../components/Layout/ContainerDefault";
import DefaultBanner from '../components/Shared/Banner/DefaultBanner';
import Contact from '../components/Shared/ContactUs/Contact';
function ContactUs() {
  const [banner, setBanner] = useState([]);

  const Contactus = {title: "Contact"}

  useEffect(() => {

    (async () => {
      const bannerss = await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "/contact-page?populate[0]=BannerImage"
        )
        .then((data) => {
          setBanner(data.data.data.attributes);
        });
        
    })();
  }, []);
  return (
    <div>
        <ContainerDefault>

            <DefaultBanner content={banner?.BannerContent} banimg={process.env.REACT_APP_IMAGE_BASE_URL + banner?.BannerImage?.data?.attributes?.url}/>
            <Contact/>


        </ContainerDefault>
    </div>
  )
}

export default ContactUs