import React from 'react';
import HtmlParser from "react-html-parser";

function QualityAssurance({Quality}) {
  return (
    <div>
        <div className="QualityAssurance" id="quality-assurance">
            <div className="row">
                <div className="col-md-7 QualityAssurance-img-con">
                    <img className="img-responsive QualityAssurance-img " src={process.env.REACT_APP_IMAGE_BASE_URL + Quality?.Quality?.Image?.data?.attributes?.url} />
                </div>

                <div className="col-md-5">
                    <div className="QualityAssurance-con">
                        <p className="txt2"> {HtmlParser(Quality?.Quality?.Title)}</p>
                        <p className="txt3"> {HtmlParser(Quality?.Quality?.Content)}
                        </p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default QualityAssurance